<template>
  <div class="row">
    <div class="col-4">
      <label>Select a Role</label>
      <select class="form-select" v-model="organizationSelectValue" :id="organizationSelectValue" @change="changeOrganizationView">
        <option :value="-1">User Type</option>
        <option :value="7">All Users</option>
        <option :value="1" v-if="authUser.position.roleHierarchy === 1">ESU Administrator</option>
        <option :value="2" v-if="authUser.position.roleHierarchy <= 3">District Administrator</option>
        <option :value="3" v-if="authUser.position.roleHierarchy <= 4">Supervisor</option>
        <option :value="4">Para Educator</option>
        <option :value="5" v-if="authUser.position.roleHierarchy === 1">ESUCC/UNL</option>
        <option :value="6" v-if="authUser.position.roleHierarchy === 1">Nebraska Department of Education</option>
      </select>
      <div v-if="organizationSelectValue !== -1">
        <label>Filter By:</label>
        <div class="input-group mb-1">

          <input class="form-control" type="search" v-model="searchParam"
                 placeholder="Name, Email, ESU, District, or School" aria-label="Search Query"
                 aria-describedby="button-addon2"/>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div class="row">
        <div>
          <img src="/para_table_view_placeholder.jpg" width="250" height="170"
               alt="Seven Children standing in front of a wall with a map of the world on on the left, all smiling and carrying backpacks.">
        </div>
      </div>
      <div class="row" v-if="organizationSelectValue !== -1">
        <div>
          <button @click="exportCsvHelper" class="btn-primary button">Export Results to CSV</button>
        </div>
      </div>
    </div>


  </div>
  <div class="row" v-if="!reloadTable">
    <user-table :field-headers="fields" :user-data="userLists" :auth-token="authToken"
                :viewing-paras="showReportCol" :search-param="searchParam"/>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import {useStore} from "vuex";
import {API_URL} from "../../../Constants";
import axios from "axios";
import UserTable from "@/views/User/UserTable";
import {isEmpty} from "lodash/lang";
import {exportCsv} from "@/ExportCsv";
import {isSuperUser} from "@/RoleCheck";
import {exportCertEmail} from "@/Helpers/ApiCalls/ReportAPICalls";
import Swal from "sweetalert2";


export default {
  name: "UserTableList",
  components: {UserTable},
  setup() {
    const store = useStore();
    const authUser = computed(() => store.getters['auth/user']);
    const authToken = computed(() => store.getters['auth/authenticated']);
    const roleHierarchyId = ref(-1);
    const user = ref({});
    const userLists = ref([]);
    const fields = ref({});
    const userListNotEmpty = ref(false);
    const organizationSelectValue = ref(-1);
    const exportFileName = ref("");
    const searchParam = ref("");
    const reloadTable = ref(false);

    onBeforeMount(() => {
      setDefaultValues();
      // getDistrictAdmins();
      if (localStorage.getItem('storedRoleSearch')) {
        organizationSelectValue.value = parseInt(localStorage.getItem('storedRoleSearch'));
        changeOrganizationView();
      }

      if (localStorage.getItem('storedRoleHeaders')) {
        fields.value = JSON.parse(localStorage.getItem('storedRoleHeaders'));
      }

      if (localStorage.getItem('csvFileNamePrefix')) {
        exportFileName.value = localStorage.getItem('csvFileNamePrefix');
      }

      if (localStorage.getItem('userFilterQuery')) {
        searchParam.value = localStorage.getItem('userFilterQuery');
      }

      if (localStorage.getItem('userSearchHeaders')) {
        fields.value = JSON.parse(localStorage.getItem('userSearchHeaders'));
      }
    })

    const showReportCol = computed(() => {
      return organizationSelectValue.value === 4 || organizationSelectValue.value === 7;
    })

    function changeOrganizationView() {
      reloadTable.value = true;
      changeOrgViewHelper();
      reloadTable.value = false;
    }

    function changeOrgViewHelper() {
      switch (organizationSelectValue.value) {
        case 1:
          getEsuAdmins();
          exportFileName.value = "esu_admins";
          break;
        case 2:
          getDistrictAdmins();
          exportFileName.value = "district_admins";
          break;
        case 3:
          getInstructors();
          exportFileName.value = "instructors";
          break;
        case 4:
          getParaEducators();
          exportFileName.value = "paraeducators";
          break;
        case 5:
          getSuperUsers();
          exportFileName.value = "superusers";
          break;
        case 6:
          getNdeUsers();
          exportFileName.value = "nde_users";
          break;
        case 7:
          getAllUsers();
          exportFileName.value = "all_users";
          break;
        default:
          clearOldUsers();
          clearOldHeaders();
          localStorage.removeItem('userSearchResults');
          localStorage.removeItem('storedRoleSearch');
          localStorage.removeItem('userSortCol');
          localStorage.removeItem('userSortReversed');
          localStorage.removeItem('filteredUserResults');
          localStorage.removeItem('userSearchHeaders');
          localStorage.removeItem('userFilterQuery');
          exportFileName.value = "";
          break;
      }
      localStorage.setItem('csvFileNamePrefix', exportFileName.value);
      localStorage.setItem('storedRoleSearch', organizationSelectValue.value.toString());
      if (fields.value != null) localStorage.setItem('userSearchHeaders', JSON.stringify(fields.value));
    }


    function setDefaultValues() {
      roleHierarchyId.value = authUser.value.position.idPosition;
      getUserDefaults();
    }

    async function getUserDefaults() {
      let get_uri = API_URL + "/user/byId?_idUser=" + authUser.value.idUser;
      await axios.get(get_uri, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        user.value = result.data;
      })
    }

    async function getInstructors() {
      if (!isEmpty(userLists.value)) clearOldUsers();
      const get_uri = API_URL + "/user/listInstructors";
      if (authUser.value.position.roleHierarchy === 1) {
        setFieldHeadersForAllSearch();
      } else if (authUser.value.inNebraska) {
        setFieldHeadersForSchoolUsers();
      } else {
        setFieldHeadersForNonNebraskaUsers();
      }
      userLists.value = await getNonParaUserData(get_uri);
    }

    async function getEsuAdmins() {
      if (!isEmpty(userLists.value)) clearOldUsers();
      const get_uri = API_URL + "/user/listEsuAdmins";
      setFieldHeadersForEsuOrNdeUsers();
      userLists.value = await getNonParaUserData(get_uri);
    }

    async function getDistrictAdmins() {
      if (!isEmpty(userLists.value)) clearOldUsers();
      const get_uri = API_URL + "/user/listAdmins";
      if (authUser.value.position.roleHierarchy === 1) {
        setFieldHeadersForAllSearch();
      } else if (authUser.value.inNebraska) {
        setFieldHeadersForDistrictUsers();
      } else {
        setFieldHeadersForNonNebraskaUsers();
      }
      userLists.value = await getNonParaUserData(get_uri);
    }

    async function getNdeUsers() {
      if (!isEmpty(userLists.value)) clearOldUsers();
      const get_uri = API_URL + "/user/listNdeUsers";
      setFieldHeadersForEsuOrNdeUsers();
      userLists.value = await getNonParaUserData(get_uri);
    }

    async function getSuperUsers() {
      if (!isEmpty(userLists.value)) clearOldUsers();
      const get_uri = API_URL + "/user/listSuperUsers";
      setFieldHeadersForEsuOrNdeUsers();
      userLists.value = await getNonParaUserData(get_uri);
    }

    async function getParaEducators() {
      if (authUser.value.position.roleHierarchy === 1) {
        setFieldHeadersForAllSearch();
      } else if (authUser.value.inNebraska) {
        setFieldHeadersForSchoolUsers()
      } else {
        setFieldHeadersForNonNebraskaUsers();
      }
      if (!isEmpty(userLists.value)) clearOldUsers();
      let get_uri = API_URL + "/user/listParas";
      await axios.get(get_uri, {
        headers: {
          Authorization: "Bearer " + authToken.value
        }
      }).then((response) => {
        response.data.sort((a, b) => a.lastName > b.lastName ? 1 : -1);
        userLists.value = setSchoolUserObjects(response.data);
        localStorage.setItem('storedRoleHeaders', JSON.stringify(fields.value));
      })
    }

    async function getAllUsers() {
      if (!isEmpty(userLists.value)) clearOldUsers();
      setFieldHeadersForAllSearch();
      let get_uri = API_URL + "/user/scopedPersonnel";
      await axios.get(get_uri, {
        headers: {
          Authorization: "Bearer " + authToken.value
        }
      }).then((response) => {
        response.data.sort((a, b) => a.lastName > b.lastName ? 1 : -1);
        userLists.value = setSchoolUserObjects(response.data);
      })
    }

    async function getRemovedUsers() {
      if (!isEmpty(userLists.value)) clearOldUsers();
      setFieldHeadersForAllSearch();
      let get_uri = API_URL + "/user/showRemoved";
      await axios.get(get_uri, {
        headers: {
          Authorization: "Bearer " + authToken.value
        }
      }).then((response) => {
        response.data.sort((a, b) => a.lastName > b.lastName ? 1 : -1);
        userLists.value = setSchoolUserObjects(response.data);
      })
    }

    function setFieldHeadersForSchoolUsers() {
      fields.value = {
        name: "Name",
        email: "Email",
        esu: "ESU",
        district: "District",
        school: "School",
        state: "State",
        country: "Country",
        registrationDate: "Registration Date",
        position: "Role",
        accountStatus: "Account Disabled/Enabled"
      }
    }

    function setFieldHeadersForDistrictUsers() {
      fields.value = {
        name: "Name",
        email: "Email",
        esu: "ESU",
        district: "District",
        state: "State",
        country: "Country",
        registrationDate: "Registration Date",
        position: "Role",
        accountStatus: "Account Disabled/Enabled"
      }
    }

    function setFieldHeadersForEsuOrNdeUsers() {
      fields.value = {
        name: "Name",
        email: "Email",
        esu: "ESU",
        state: "State",
        country: "Country",
        registrationDate: "Registration Date",
        position: "Role",
        accountStatus: "Account Disabled/Enabled"
      }
    }

    function setFieldHeadersForNonNebraskaUsers() {
      fields.value = {
        name: "Name",
        email: "Email",
        educationalOrganization: "Organization",
        state: "State",
        country: "Country",
        registrationDate: "Registration Date",
        position: "Role",
        accountStatus: "Account Disabled/Enabled"
      }
    }

    function setFieldHeadersAllUsersSuperUserView() {
      fields.value = {
        name: "Name",
        email: "Email",
        esu: "ESU",
        district: "District",
        school: "School",
        educationalOrganization: "Outstate Organization",
        state: "State",
        country: "Country",
        registrationDate: "Registration Date",
        position: "Role",
        accountStatus: "Account Disabled/Enabled"
      }
    }

    function setFieldHeadersForAllSearch() {
      if (!authUser.value.inNebraska) {
        setFieldHeadersForNonNebraskaUsers();
      } else if (authUser.value.position.roleHierarchy === 1) {
        setFieldHeadersAllUsersSuperUserView();
      } else {
        fields.value = {
          name: "Name",
          email: "Email",
          esu: "ESU",
          district: "District",
          school: "School",
          state: "State",
          country: "Country",
          registrationDate: "Registration Date",
          position: "Role",
          accountStatus: "Account Disabled/Enabled"
        }
      }
    }

    async function getNonParaUserData(getUri) {
      return await axios.get(getUri, {
        headers: {
          Authorization: "Bearer " + authToken.value,
        },
      }).then((response) => {

        response.data.sort((a, b) => a.lastName > b.lastName ? 1 : -1);
        localStorage.setItem('storedRoleHeaders', JSON.stringify(fields.value));
        if (getUri.includes("Instructor")) return setSchoolUserObjects(response.data);
        return setUserObjects(response.data);
      })
    }

    function clearOldUsers() {
      while (userLists.value.length > 0) {
        userLists.value.pop();
      }
    }

    function clearOldHeaders() {
      for (const key in fields.value) {
        delete fields.value[key];
      }
    }

    function setUserObjects(userList) {
      let userObjList = [];
      for (let i = 0; i < userList.length; i++) {
        let user = {
          name: userList[i]['firstName'] + " " + userList[i]['lastName'],
          email: userList[i]['email'],
          state: userList[i]['stateName'],
          country: userList[i]['countryName'],
          esu: userList[i]['esuName'],
          district: userList[i]['districtName'],
          accountStatus: userList[i]['accountEnabled'] !== null ? userList[i]['accountEnabled'] : false,
          registrationDate: userList[i]['registrationDate'],
          idUser: btoa(userList[i]['idUser']),
          position: userList[i]['positionName'],
          educationalOrganization: (userList[i]['outStateOrgName'] !== null && userList[i]['outStateOrgName'] !== undefined)
              ? userList[i]['outStateOrgName'] : "",
          canvasId: userList[i]['canvasId'],
          enrollmentType: userList[i]['idEnrollmentType']
        }
        userObjList.push(user);
      }
      return userObjList;
    }

    function setSchoolUserObjects(userList) {
      let userObjList = [];
      for (let i = 0; i < userList.length; i++) {

        let user = {
          name: userList[i]['firstName'] + " " + userList[i]['lastName'],
          email: userList[i]['email'],
          state: userList[i]['stateName'],
          country: userList[i]['countryName'],
          esu: (userList[i]['esuName'] !== null && userList[i]['esuName'] !== undefined) ? userList[i]['esuName'] : "",
          district: (userList[i]['districtName'] !== null && userList[i]['districtName'] !== undefined) ? userList[i]['districtName'] : "",
          school: (userList[i]['schoolName'] !== null && userList[i]['schoolName'] !== undefined) ? userList[i]['schoolName'] : "",
          accountStatus: userList[i]['accountEnabled'] !== null ? userList[i]['accountEnabled'] : false,
          registrationDate: userList[i]['registrationDate'],
          idUser: btoa(userList[i]['idUser']),
          position: userList[i]['positionName'],
          educationalOrganization: (userList[i]['outStateOrgName'] !== null && userList[i]['outStateOrgName'] !== undefined)
              ? userList[i]['outStateOrgName'] : "",
          canvasId: userList[i]['canvasId'],
          enrollmentType: userList[i]['enrollmentType'].idEnrollmentType
        }
        userObjList.push(user);
      }
      return userObjList;
    }

    function exportCsvHelper() {
      // let csv_headers = Object.keys(fields.value);
      let csv_arr = [];

      csv_arr.push(Object.keys(fields.value));

      let tmp = JSON.parse(localStorage.getItem('filteredUserResults'));
      if (authUser.value.inNebraska) {
        for (let user of tmp) {
          let user_temp = [user.name,
            user.email,
            user.esu,
            user.district,
            user.school,
            user.educationalOrganization,
            user.state,
            user.country,
            user.registrationDate,
            user.position,
            user.accountStatus]
          csv_arr.push(user_temp)
        }
      } else {
        for (let user of tmp) {
          let user_temp = [
            user.name,
            user.email,
            user.educationalOrganization,
            user.state,
            user.country,
            user.registrationDate,
            user.position,
            user.accountStatus
          ]

          csv_arr.push(user_temp);
        }
        // csv_arr = tmp.map(user => [
        //   user.name,
        //   user.email,
        //   user.educationalOrganization,
        //   user.state,
        //   user.country,
        //   user.registrationDate,
        //   user.position,
        //   user.accountStatus
        // ])
      }

      const date = new Date();

      const formatted_date = [date.getMonth() + 1, date.getDate(), date.getFullYear()].join('_');

      let file_name = exportFileName.value + '_' + formatted_date + '.csv'
      exportCsv(csv_arr, file_name);
    }



    return {
      userLists,
      fields,
      authToken,
      userListNotEmpty,
      organizationSelectValue,
      authUser,
      showReportCol,
      searchParam,
      reloadTable,
      exportCsvHelper,
      changeOrganizationView,
    }
  }
}
</script>

<style scoped>

</style>